<template>
  <!-- 根据 icon 类型决定使用的不同类型的图标组件 -->
  <el-icon v-if="icon && icon.startsWith('el-icon')" :style="'width: ' + size + '!important;'">
    <component :is="icon.replace('el-icon-', '')" />
  </el-icon>
  <svg-icon v-else-if="icon" :icon-class="icon" :size="size" />
  <svg-icon v-else icon-class="menu" :size="size" />
</template>

<script>
import SvgIcon from "@/components/SvgIcon.vue";

export default {
    name: "MixIcon",
    components: {
        SvgIcon
    },
    props: {
        icon: {
            type: String,
            default: "",
        },
        size: {
            type: String,
            default: "20px",
        },
    }
}
</script>

