export function getManagerParamStr(param) {
    if (!param) {
        return null
    }

    let stmp = new Date().getTime();

    param.timestamp = stmp

    const sortedKeys = Object.keys(param).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

    // 基于排序后的键值对创建拼接字符串
    let paramString = sortedKeys.map(key => `${key}=${param[key]}`);
    paramString = paramString.join('') + "2024@geniuslighting.com"

    const CryptoJS = require('crypto-js');
    let sign = CryptoJS.MD5(paramString).toString().toLowerCase();

    delete param.timestamp

    return {
        timestamp: stmp,
        sign: sign,
        url: Object.keys(param).map(key => `${key}=${param[key]}`).join("&")
    }
}

export function statistics(page, source, product_id) {
    if (page === "") {
        return
    }

    let param = {
        page: page,
        source: source,
        product_id: product_id
    }

    let paramObj = getManagerParamStr(param);
    if (!paramObj) {
        return;
    }

    let url = ApiURL + "/v1/portal/statistics?" + paramObj.url ;

    let xhr = new XMLHttpRequest();
    xhr.open('GET', url, false);  // 使用 false 参数使请求变成同步调用
    xhr.setRequestHeader('Portal-Timestamp', paramObj.timestamp);
    xhr.setRequestHeader('Portal-Sign', paramObj.sign);
    xhr.send();
}

const BASE_URL = "https://www.geniuslighting.com"
//const BASE_URL = "http://192.168.145.157"

export const WEB_URL = BASE_URL + '/index.html'
export const MOBILE_URL = BASE_URL + '/m/index.html'

export function isMobile() {
    let userAgent = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
}

export const ImageOSSUrl = BASE_URL + '/static/caizhan' ;
export const DataOSSURL = BASE_URL + "/data/" ;
export const ApiURL = BASE_URL + "/api"

