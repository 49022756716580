<template>
    <div class="product-list">
        <el-image
            style="width: 100%;"
            :src="imageUrl + productConfigData.image"
            fit="contain">
        </el-image>

            <div class="product-item-view">
                <div class="product-item-row">
                    <div class="product-item-main-pic">
                        <PicZoom
                            :url="mainPicThumb"
                            :scale="3"></PicZoom>
                    </div>
                    <div class="product-item-desc">
                        <div class="product-item-desc-top">
                            <div class="product-item-desc-top-left">
                                <div class="product-item-desc-row product-item-title">{{productItemData.typeName}}</div>
                                <div class="product-item-desc-row product-item-property">{{productColumnData.mode}}：{{productItemData.mode}}</div>
                                <div class="product-item-desc-row product-item-property">{{productColumnData.color}}：{{productItemData.color}}</div>
                                <div class="product-item-desc-row product-item-property">{{productColumnData.power}}：{{productItemData.power}}</div>
                            </div>
                        </div>
                        <div class="product-item-desc-row product-item-thumb">
                            <swiper
                                :modules="modules"
                                navigation
                                watch-slides-progress
                                @slideChange="onSlideChange"
                                slidesPerView="7.5"
                                spaceBetween="20px"
                                style="width:100%;cursor: pointer"
                            >
                                <swiper-slide v-for="(item, idx) in mainImageList" :key="idx"
                                              @click="onThumbClicked(imageUrl + item)">
                                    <el-image
                                        style="width: 100%"
                                        :src="imageUrl + item"
                                        fit="contain">
                                    </el-image>
                                </swiper-slide>
                            </swiper>
                        </div>

                    </div>
                </div>
                <div class="product-item-row">
                    <span class="product-item-gap">{{productConfig.paramTitle}}</span>
                </div>
                <template v-for="(column, key) in productColumnData" :key="key">
                    <div class="product_params_row" v-if="key in productItemData && productItemData[key] != '' && key !== 'id'">
                        <div class="product_params_view" >
                            <div class="product_params_item_row" >
                                <div class="product_params_title">{{column}}:</div>
                                <div class="product_params_value">{{productItemData[key]}}</div>
                            </div>
                        </div>
                    </div>
                </template>
                <span class="product-item-gap"></span>
                <div class="product-item-row" v-if="detailImageList.length > 0">
                    <span class="product-item-gap">{{productConfig.detailTitle}}</span>
                </div>
                <div class="product-item-desc" v-if="detailImageList.length > 0">
                    <el-image v-for="(item, index) in detailImageList" :key="index"
                        style="width: 100%;margin-top: 40px;"
                        :src="imageUrl + item"
                        fit="contain">
                    </el-image>
                </div>
            </div>

    </div>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import {ImageOSSUrl} from "@/js/netApi";
import {Navigation, Thumbs} from "swiper";
import {ref} from "vue";
import PicZoom from "vue-piczoom";

export default {
    name: "ProductList",
    components: {
        Swiper,
        SwiperSlide,
        PicZoom
    },
    setup() {
        const thumbsSwiper = ref(null);
        const setThumbsSwiper = (swiper) => {
            thumbsSwiper.value = swiper;
        };
        let currentSlide = ref(0)

        const onSlideChange = (e) => {
            currentSlide.value = e.realIndex
        };
        return {
            Thumbs,
            thumbsSwiper,
            currentSlide,
            onSlideChange,
            setThumbsSwiper,
            modules: [Navigation,Thumbs],
        };
    },
    props: {
        productConfig: {},
        columnData: {}
    },
    watch: {
        'productConfig': function () {
            this.productConfigData = this.productConfig
        },
        'columnData': function () {
            this.productColumnData = this.columnData
        }
    },
    data() {
        return {
            selectedCategory: '',
            imageUrl: ImageOSSUrl,
            selectedItem: '',
            productItemData: {},
            mainPicThumb: '',
            productParamArr: [],
            productConfigData: {},
            productColumnData: {},
            mainImageList: [],
            detailImageList: [],
        }
    },
    mounted() {
        this.productItemData = JSON.parse(localStorage.getItem('currentProduct'))
        this.productConfigData = this.productConfig
        this.productColumnData = this.columnData

        this.setProductParamArr()

        this.mainImageList = this.productItemData.mainImageList.split(',')
        if (this.productItemData.detailImageList === "") {
            this.detailImageList = []
        } else {
            this.detailImageList = this.productItemData.detailImageList.split(',')
        }

        let mainImage = this.mainImageList[0]

        setTimeout(() => {
            this.onThumbClicked(this.imageUrl + mainImage)
        }, 200);
    },
    methods: {
        onBannerClicked(idx,url,type) {
            console.log(idx)
            console.log(url)
            console.log(type)
        },

        setProductParamArr() {
            this.productParamArr = []

            if (!this.productItemData) {
                return
            }

            let cnt = 0
            let paramsArr = []
            for (let key in this.productItemData) {
                let param = this.productItemData[key]
                if ((!param) || (typeof param !== 'object') || (!param.value) || (Array.isArray(param.value))) {
                    continue
                }

                if (cnt <= 1) {
                    paramsArr.push(param)
                    cnt++
                } else {
                    this.productParamArr.push(paramsArr)
                    paramsArr = []
                    paramsArr.push(param)
                    cnt = 1
                }
            }

            this.productParamArr.push(paramsArr)



        },

        onCategoryItemClicked(index, idx) {
            this.selectedItem = index + '-' + idx

        },

        onThumbClicked(imgSrc) {
            this.mainPicThumb = imgSrc

        },

        gotoAlibaba(url) {
            window.open(url, '_blank');
        }
    }
}
</script>

<style scoped>
.product-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.slideItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-size:cover;
    background-repeat: no-repeat;
    color: #FFF;
    font-size: 18px;
    width: 100%;
    height: 100%;
}

.product-view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 85%;
    margin-top: 40px;
}

.category-view {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 20%;
    border: 1px solid #E7E9F3;
}

.category-title {
    background: #CFA88C;
    color: white;
    font-size: 20px;
    width: 100%;
    padding: 10px 0;
    text-align: center;
}

.category_item {
    font-size: 14px;
    border-top: 1px solid #E7E9F3;
    width: 95%;
    color: #818181;
    padding: 10px 0;
    margin-left: 6px;
}

.category_item_selected {
    color: #CFA88C;
    font-weight: bold;
}

.category_item:hover {
    color: #CFA88C;
    font-weight: bold;
    cursor: pointer;
}

.el-select .el-input__inner {
    font-size: 16px ;
}

.product-item-view {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 85%;
    margin-top: 40px;
    margin-bottom: 80px;
}

.product-item-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.product_params_row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height:80px;
}

.product_params_item_row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.product_params_view {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 70%;
    border: #888 solid 1px;
    margin-top: -2px;
    white-space: nowrap;
    height: 100%;
}

.product_params_title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12vw;
    border: #888 solid 1px;
    height: 100%;
    text-align: center;
    background-color: #eee;
}

.product_params_value {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 10vw;
    flex: 1;
    height: 100%;
    text-wrap: wrap;
    text-align: center;
}

.product-item-gap {
    text-align: center;
    width: 100%;
    font-size: 28px;
    font-weight: bold;
    padding: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.product-item-main-pic {
    width: 20vw;
    height: 20vw;
}

.product-item-desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin-top: 20px;
}

.product-item-desc-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.product-item-desc-top-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 70%;
}

.product-item-desc-top-right {
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-item-btn:hover {
    cursor: pointer;
}

.product-item-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border: 2px solid #CFA88C;
    color: #CFA88C;
    font-size: 14px;
}

.product-item-desc-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

}

.product-item-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
}

.product-item-property {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
}

.product-item-top .el-image {
    transition: scale 1s;
    -webkit-transition: scale 1s;
}

.product-item-top .el-image:hover {
    scale: 1.1;
}



* {
    --el-collapse-header-font-size: 14px !important;
    --swiper-navigation-size: 20px !important;
    --swiper-theme-color: #CFA88C !important;
}

</style>
