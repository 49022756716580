import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import animated from 'animate.css'
import BaiduMap from "vue-baidu-map-3x";
import { createRouter, createWebHashHistory } from 'vue-router'

import MainComponent from '@/components/MainComponent'
import SearchResultComponent from "@/components/SearchResultComponent";
import ServiceComponent from "@/components/ServiceComponent";
import ProductList from "@/components/ProductList";
import AboutComponent from "@/components/AboutComponent";
import DesignComponent from "@/components/DesignComponent";
import DesignFlowComponent from "@/components/DesignFlowComponent";
import CaseComponent from "@/components/CaseComponent";
import ProductItemComponent from "@/components/ProductItem" ;
import ProjectComponent from "@/components/ProjectComponent.vue";
import * as netApi from "@/js/netApi";

const routes = [
    {
        path: '/',
        name: 'main',
        components: {
            MainComponent,
        }
    },
    {
        path: '/search',
        name: 'search',
        components: {
            SearchResultComponent,
        }
    },
    {
        path: '/service',
        name: 'service',
        components: {
            ServiceComponent,
        }
    },
    {
        path: '/product/',
        name: 'product',
        components: {
            ProductList,
        }
    },
    {
        path: '/about',
        name: 'about',
        components: {
            AboutComponent,
        }
    },
    {
        path: '/design',
        name: 'design',
        components: {
            DesignComponent,
        }
    },
    {
        path: '/designFlow',
        name: 'designFlow',
        components: {
            DesignFlowComponent,
        }
    },
    {
        path: '/case',
        name: 'case',
        components: {
            CaseComponent,
        }
    },
    {
        path: '/project',
        name: 'project',
        components: {
            ProjectComponent,
        }
    },
    {
        path: '/productItem',
        name: 'productItem',
        components: {
            ProductItemComponent,
        }
    },
]

if (netApi.isMobile()) {
    window.location.href = netApi.MOBILE_URL + window.location.search
}

const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHashHistory(),
    routes, // `routes: routes` 的缩写
})

const urlParams = new URLSearchParams(window.location.search);
const params = {};
for (const [key, value] of urlParams.entries()) {
    params[key] = value;
}

const app = createApp(App, {urlParams: params});
app.use(ElementPlus)
app.use(animated)
app.use(router)

app.use(BaiduMap, {
    // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
    ak: '3SeA3VVey5yYto9nx2MFVXuZt1uOyo1I',
    // v:'2.0',  // 默认使用3.0
    // type: 'WebGL' // ||API 默认API  (使用此模式 BMap=BMapGL)
});

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.mount('#app')
