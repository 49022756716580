<template>
    <div class="footer">
        <div class="footer_link_view">
            <div class="footer_link_view_left">
                <div class="footer_link_view_item" v-for="(item, index) in menuList" :key=index >
                    <template v-if="item.items.length > 0">
                    <span class="item_title">{{item.title}}</span>
                    <div v-for="(link, idx) in item.items" :key="idx">
                        <span class="item_link" v-if="!(link.hasOwnProperty('enable')) && !link.enable"
                            @click="onMenuClicked(item.id, link.id, link.path)">
                            {{link.title}}
                        </span>
                    </div>
                    </template>
                </div>
            </div>
            <div class="footer_link_view_shop">
                <el-image
                    style="width: 25%;"
                    :src="imageUrl + data.logo"
                    fit="contain">
                </el-image>
                <span class="copyright">{{data.copyright}}</span>
            </div>
        </div>

    </div>
</template>

<script>
import {ImageOSSUrl} from "@/js/netApi";

export default {
    name: "FooterComponent",
    props: {
        'data': {
            default: {}
        },
        menuData: {
            default: {}
        },
    },
    methods: {
        onMenuClicked(idx, subIdx, url) {
            this.$emit('onMenuClicked', idx, subIdx, url)
        }
    },
    data() {
        return {
            imageUrl: ImageOSSUrl,
            menuList: [],
        }
    },
    mounted() {
        this.menuList = this.menuData
    },
    watch: {
        "menuData": function (newVal, oldVal) {
            this.menuList = newVal

            console.log(oldVal)
        }
    }
}
</script>

<style scoped>
.footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: #333333;
    box-sizing: border-box;
    padding:40px;
}

.footer_link_view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
    margin-bottom: 80px;
}

.footer_link_view_left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 60%;
}

.footer_link_view_item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

}

.footer_link_view_shop {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.item_title {
    font-size: 16px;
    color: #ddd;
    font-weight: bold;
    margin-bottom: 20px;
}

.item_link {
    color: #d0d0d080;
    font-size: 14px;
    margin-bottom: 10px;
    cursor: pointer;
}

.item_link:hover {
    color: #CFA88C;
}

.copyright {
    color: #d0d0d080;
}
</style>
