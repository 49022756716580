class LocalStorage {
    // 添加
    setStorage(key, value) {
        window.localStorage.setItem(key, JSON.stringify(value))
    }
    // 查找
    getStorage(key) {
        // obj=>sting=>obj
        const value = window.localStorage.getItem(key)
        if ((typeof value === "undefined") || (value === "undefined")) {
            return ""
        }

        if (value) {
            return JSON.parse(value)
        }
    }
    // 删除
    delStorage(key) {
        window.localStorage.removeItem(key)
    }
    // 清理
    clearStorage() {
        window.localStorage.clear()
    }
}

export default new LocalStorage()
